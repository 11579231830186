<template>
  <div>
    <div class="group">
      <template v-for="(group,index) in data">
        <template v-if="group.values && group.values.length > 0">
          <div class="row sctp-flex">
            <div class="header sctp-info">
              <template v-if="group.label">
                {{ group.label }}：
              </template>
            </div>
            <div class="body sctp-flex-item-1" :class="{ 'closure' : !showFlag[index]}">
              <template v-for="cond in group.values">
                <div
                  :key="cond[dataValue]"
                  :class="{ 'selected' : isSelected(group.label, cond)}"
                  @click="onClickCondition(group.label, cond)"
                  class="condition-item"
                >
                  {{ cond[label] }}
                </div>
              </template>
            </div>
            <div class="footer">
              <div @click="showMore(index)">
                <template v-if="!showFlag[index]">
                  更多 <i class="el-icon-arrow-down"></i>
                </template>
                <template v-else>
                  收起 <i class="el-icon-arrow-up"></i>
                </template>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'condition',
  props: {
    label: {
      type: String,
      default: 'title'
    },
    dataValue: {
      type: String,
      default: 'value'
    },
    /**
     * [{label: '', values: []}]
     */
    data: {
      type: Array | Object
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      showFlag: {},
      selected: {}
    }
  },
  watch: {},
  methods: {
    onClickCondition(label, target) {
      const temp = this.selected
      temp[label] = target[this.dataValue]
      this.selected = null
      this.selected = temp
      this.$emit('select', {label: label, target: target})
    },
    showMore(index) {
      const {showFlag} = this
      showFlag[index] = !showFlag[index]
      this.showFlag = null
      this.showFlag = showFlag
    },
    reset() {
      this.selected = {}
    }
  },
  computed: {
    isSelected() {
      const {selected, dataValue, value} = this
      let merge = {}
      if (value) {
        merge = {...merge, ...value}
      }
      if (selected) {
        merge = {...merge, ...selected}
      }
      return (label, target) => {
        return merge[label] === target[dataValue] || false
      }
    }
  }
}
</script>

<style scoped>
/*@formatter:off*/
  .group {position: relative;font-size: 13px;}
  .row {margin: 0 20px;position: relative;}
  .header {width: 100px;margin-top: 9px;position: relative;}
  .footer {width: 80px;margin-top: 9px;position: relative;}
  .footer * {cursor: pointer;}
  .body {display: flex;flex-wrap: wrap;position: relative;max-height: 180px;overflow-y: auto;margin-right: 15px;}
  .row + .row {border-top: 1px #e5e5e5 dashed;}
  .body.closure {flex-wrap: nowrap!important;overflow: hidden!important;}
  .condition-item {margin: 9px 40px 9px 0;height: 18px;color: rgba(41, 43, 51, 0.8);text-decoration: none;cursor: pointer;flex-shrink: 0;}
  .condition-item.selected {color: #F90;}
  /*@formatter:on*/
</style>
